import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our PancakeSwap Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Swap
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Add Liquidity
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Liquidity Pool Tokens
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Yield farming
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Multiple wallet support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Syrup Pools
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  NFT Marketplace
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Lottery
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Trading Competition
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  IFO 
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Swap
                        </p>
                        <p className="pharagraph">You can swap your tokens with other tokens in the Binance smart chain quickly with our PancakeSwap clone script built-in smart contracts.
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancakeswap/swap.png"
                      alt="Swap"
                      width={478}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Add Liquidity
                        </p>
                        <p className="pharagraph">Users can provide liquidity for a specific trading pair in the liquidity pool and earn rewards.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/add-liquidity.png"
                      alt="Add Liquidity"
                      width={478}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Liquidity Pool Tokens

                        </p>
                        <p className="pharagraph">Users earn LP tokens to provide liquidity for selected pairs in the liquidity pool. These tokens can be staked in farms and users can earn more token rewards.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/liquidity-pool-tokens.png"
                      alt="Liquidity Pool Tokens"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Yield Farming
                        </p>
                        <p className="pharagraph">Our PancakeSwap clone script enables one of the best DeFi products - Yield Farming. Users earn rewards for staking their LP tokens in the farms that are powered by smart contracts.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/yield-farming.png"
                      alt="Yield farming"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Multiple Wallet Support
                        </p>
                        <p className="pharagraph">Integrate all mainstream and custom wallets into the platform which includes Metamask, SafePal, Trust Wallet, WalletConnect, MathWallet, Binance Chain, Coin98, and TokenPocket.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/multiple-wallet-support.png"
                      alt="Multiple wallet support"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Syrup Pools
                        </p>
                        <p className="pharagraph">Syrup pools are one of the DeFi earning modules where users can stake their preferred tokens in the respective token pools for a specific time to earn a high income.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/syrup-pools.png"
                      alt="Syrup Pools"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">NFT Marketplace
                        </p>
                        <p className="pharagraph">Enables your users to trade NFTs on the Binance smart chain.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/nft-marketplace.png"
                      alt="NFT Marketplace"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Lottery
                        </p>
                        <p className="pharagraph">Our PancakeSwap clone script comes with a unique lottery module where users can buy their tickets and earn rewards based on the number of their tickets. If their ticket number matches the winning number, users can claim their rewards. 
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/lottery.png"
                      alt="Lottery"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">Trading Competition
                        </p>
                        <p className="pharagraph">Make a team and compete with other teams in the trading competition where the winning team will be selected based on the trade volume. The team with the highest trading volume will be the winner.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/trading-competition.png"
                      alt="Trading Competition"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <p className="heading-h4">IFO 
                        </p>
                        <p className="pharagraph">Here users can buy newly launched tokens in the Binance smart chain. Create a profile, stake your token in the IFO pool, and earn IFO credits which can be used to secure your commitment limit in the next IFO sale and buy new tokens.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancakeswap/ifo.png"
                      alt="IFO"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures