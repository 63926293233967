import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of</span>
            Our PancakeSwap Clone Script </h3>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures to build decentralized exchange software
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/binance/planning-and-requirment-gathering.png"
                alt="Development approach"
                width={310}
              />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <p className="sub-heading">Requirement Gathering</p>
            <p className="pharagraph" >Before installing the script we will analyze your exact requirements and expectations to incorporate them within the setup.
            </p>
            <br />
            <p className="sub-heading">Planning </p>
            <p className="pharagraph" >Our business experts will plan and provide the finest solution depending on the client’s business concept.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <p className="sub-heading">Designing</p>
            <p className="pharagraph" >The success of your crypto exchange depends a lot on its user interface because people will be interacting with your PancakeSwap clone script through the interface.</p>
            <br />
            <p className="sub-heading">Development</p>
            <p  className="pharagraph">The script needs to be customized with unique branding and feature list. This can be done during the development phase.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
          <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/binance/design-development.png"
                alt="Designing"
                width={259}
              /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/binance/testing-and-deployment.png"
                alt="Testing and Deployment"
                width={340}
              />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <p className="sub-heading">Testing</p>
            <p  className="pharagraph">Rigorous testing ensures that once the script is installed it is ready for public use and the rigors of crypto transactions.
            </p>
            <br />
            <p className="sub-heading">Deployment</p>
            <p  className="pharagraph mb-0">Based on your requirement, the entire setup will be deployed and activated under your domain or on individual play stores.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach