import React from 'react'
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,   
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">PancakeSwap</span> Clone Script</h1>
                <p className="sub-heading">Create your own Binance Smart Chain (BSC) based DeFi exchange with our fully optimizable PancakeSwap clone script.</p>
                <ul>
                  <li className="pharagraph">It is 100% similar to the PancakeSwap framework.
                  </li>
                  <li className="pharagraph">Our script holds functionalities like Farming, Staking, and many more.
                  </li>
                  <li className="pharagraph">Fully customizable yet can be used straight out of the box.
                  </li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
              <div className="screen">
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/pancake-new/pancakeswap-clone-laptop-view.png"
                  alt="pancakeswap clone laptop front view"
                  width={876}
                />
                <div className="outder">
                  <Slider {...settings}>
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/white-label-pancakeswap-clone-script-software.png"
                      alt="Pancakeswap clone script slider1"
                      width={647}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/pancakeswap-clone-script.png"
                      alt="Pancakeswap clone script slider2"
                      width={647}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/pancakeswap-clone.png"
                      alt="Pancakeswap clone script slider3"
                      width={647}
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner