import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
              Coinsclone PancakeSwap Clone to create your own decentralized exchange?</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 text-center" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/pancake-new/why-choose-pancakeswap-clone-script.png"
                alt="Why Choose our Pancakeswap Clone Script"
                width={420}
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">To build a decentralized exchange like PancakeSwap instantly with a professional touch, you need guidance from the genuine PancakeSwap clone software provider - Coinsclone. Our ultra-modern white-label PancakeSwap clone software delivers you all the advanced and essential features that you need to create your own decentralized exchange. Our script includes Automated Market Making, yield farming services, liquidity pools, swapping, bridging, multiple wallet support, and various lottery ticket schemes.
              </p>
              <p className="pharagraph mb-0">Coinsclone’s ready-to-launch PancakeSwap clone script makes it easy to build a decentralized exchange and can be customized and deployed on the Binance smart chain. Our clone script works well both with a website and through a non-custodial crypto wallet. The latest API architecture in our script allows you to use the features and data in a hassle-free manner. Besides, the PancakeSwap clone we provide script also comes with an advanced setup and maintenance support. We also have a separate team of experts to offer productive technical support to all our products on a 24/7 basis.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose