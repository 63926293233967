import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you create </span>
              DEX exchange using a PancakeSwap Clone Script?
              </h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/pancake-new/why-should-you-create-dex-exchange-using-a-pancake-swap-clone-script.png"
                      alt="create DEX exchange using a PancakeSwap Clone Script"
                      width={510}
                    />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Our premier PancakeSwap clone script is devised on the most secure Blockchain - Binance Smart Chain(BSC).
                </li>
                <li className="pharagraph" >You can instantly create a DEX exchange & deploy it on the BSC network
                </li>
                <li className="pharagraph" >By using the PancakeSwap clone, you can build a decentralized exchange like PancakeSwap cost-effectively.
                </li>
                <li className="pharagraph" >Comes with a highly attractive layout. 
                </li>
                <li className="pharagraph" >Your users can swap BEP20 tokens quickly and securely.
                </li>
                <li className="pharagraph" >High customization scope.</li>
                <li className="pharagraph" >Generate high ROI in a short period
                </li>
                <li className="pharagraph" >Multi-tested and 100% error-free.
                </li>
                <li className="pharagraph" >Our clone script allows your users to connect multiple crypto wallets for swapping tokens.
                </li>
                <li className="pharagraph" >The exclusive PancakeSwap clone script we offer is loaded with high-end technical features and top-notch security modules.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould