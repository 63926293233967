import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our PancakeSwap Clone Script</h3>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <p className="heading-h4">Exchange Instantly</p>
                <p className="pharagraph">Users can trade any token in the Binance smart chain instantly by connecting their external wallets like Metamask, Binance Wallet, Trust Wallet, etc. The platform is powered by smart contracts to carry out automated transactions, leveraging the fluctuations happening within a fraction of a second.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/pancake-new/exchange-instantly.png"
                  alt="Exchange Instantly"
                  width={536}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/pancake-new/amm-automated-market-maker.png"
                  alt="AMM - Automated Market Maker"
                  width={536}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <p className="heading-h4">AMM - Automated Market Maker</p>
                <p className="pharagraph">No need for the traditional order book system. Our Pancakeswap clone script comes with liquidity pools where users provide liquidity to earn LP tokens and trade fees.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <p className="heading-h4">Fastest Speed</p>
                <p className="pharagraph">Our PancakeSwap clone script allows thousands of transactions per second, allowing multiple users to use the exchange simultaneously, without lag time.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/pancake-new/fastest-speed.png"
                  alt="Fastest speed"
                  width={536}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/pancake-new/stunning-user-interface.png"
                  alt="Stunning user interface"
                  width={536}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <p className="heading-h4">Stunning User Interface</p>
                <p className="pharagraph">Our PancakeSwap clone script provides impenetrable multilayered security that is the hallmark of blockchain technology. Multiple security protocols make it practically impossible to compromise privacy and financial transactions.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <p className="heading-h4">Multi layered security</p>
                <p className="pharagraph">Our Pancakeswap clone script provides impenetrable multilayered security that is the hallmark of the blockchain technology. Multiple security protocols make it practically impossible to compromise privacy and financial transactions.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/pancake-new/multi-layered-security.png"
                  alt="Multi layered security"
                  width={550}
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures