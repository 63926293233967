import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
                PancakeSwap Clone Script?</h2>
              </div>
            </div>

            <div className="text-center">
              <p className="pharagraph" >PancakeSwap Clone Script is a ready-made DeFi-based DEX script built on the BSC network with all the existing features of PancakeSwap. Are you planning to create your own decentralized exchange on the Binance Smart Chain(BSC)? Then our PancakeSwap clone script is the best option for you. Our PancakeSwap clone script is 100% legitimate and allows you to create a DeFi exchange on Binance Smart Chain similar to PancakeSwap to kickstart your business.
              </p>
              <p className="pharagraph">Our premium PancakeSwap clone software is not just a complete replica of the PancakeSwap as it offers many more features that are not present in the existing platform. Such as limitless flexibility, multi-lingual assistance, and seamless scaling. Just like the PancakeSwap, our clone script also allows your users to perform instant swaps using BEP-20 tokens and the BSC-ETH bridge.</p>
              <p className="pharagraph mb-0">Our bug-free PancakeSwap clone script has advanced liquidity pools. So, your users can earn rewards by staking, lending, and yield farming. Our script is built with an AMM (Automated Market Maker) and the core architecture of the script is designed as same as the PancakeSwap platform. As an admin of the platform, you can set up default algorithms for processing transactions or you can implement your own logic into the existing algorithms.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis